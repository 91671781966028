import Swiper from '../../vendors/swiper';

/**
 * Swiper: Partner reviews
 * lazy global const is called with revalidate function
 * Swiper lazyload is not used as it does not lazyload any images on first slide
 * An IntersectionObserver is to to ensure the slides only scroll when in viewport
 */

/* Which media query
************************************************************* */
function initSwiperReviewGallery() {
  const reviewGallery = document.querySelectorAll('.js-swiper-review-gallery');

  reviewGallery.forEach((element) => {
    // eslint-disable-next-line no-new
    new Swiper(element, {
      slidesPerView: 'auto',
      spaceBetween: 8,
      slidesPerGroup: 1,
      breakpoints: {
        // >=mobile
        480: {
          slidesPerView: 2,
          slidesPerGroup: 1,
        },
        // >=tablet
        640: {
          slidesPerView: 'auto',
          slidesPerGroup: 1,
          spaceBetween: 12,
        },
        // >=desktop
        768: {
          slidesPerView: 'auto',
          slidesPerGroup: 1,
          spaceBetween: 24,
        },
        // >=desktop
        1200: {
          slidesPerView: 4,
          slidesPerGroup: 1,
          spaceBetween: 24,
        },
      },
    });
  });
}

function initToplistSwiper() {
  // Get all swiper elements to be initialised
  const swiperToplists = document.querySelectorAll('.js-swiper-toplist');

  swiperToplists.forEach((element) => {
    // eslint-disable-next-line no-new
    new Swiper(element, {
      slidesPerView: 1.5,
      spaceBetween: 16,
      slidesPerGroup: 1,
      navigation: {
        nextEl: '.swiper-btn--next',
        prevEl: '.swiper-btn--prev',
        disabledClass: 'swiper-btn--disabled',
      },
      breakpoints: {
        // >=mobile
        480: {
          slidesPerView: 2.5,
          slidesPerGroup: 1,
        },
        // >=tablet
        768: {
          slidesPerView: 4,
          slidesPerGroup: 1,
        },
      },
    });
  });
}

function initSwiper() {
  initSwiperReviewGallery();
  initToplistSwiper();
}

(function () {
  initSwiper();
}());

function pingPong(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

// Call initSwiper function via debounce function.
// 200ms is roughly each frame duration when we resize on a 60hz screen
window.addEventListener('resize', pingPong(initSwiper, 200));
